export const callVideoTimeline = () => {
  return {
    grayscaleTopicsByName(name) {
      const allTopics = document.querySelectorAll(`.common-topic-name`);
      const allTranscriptBlocks = document.querySelectorAll(
        `.common-transcript-block`,
      );
      const lowOpacity = "0.1";
      if (name) {
        const topics = document.querySelectorAll(`.topic-name-${name}`);
        allTopics.forEach((topic) => {
          topic.style.opacity = lowOpacity;
        });
        allTranscriptBlocks.forEach((block) => {
          block.style.opacity = lowOpacity;
        });
        topics.forEach((topic) => {
          topic.style.opacity = "1";
          const topicName = topic.getAttribute("name");
          const allTranscriptBlocksByName = document.querySelectorAll(
            `[data-participant-name="${topicName}"]`,
          );
          allTranscriptBlocksByName.forEach((block) => {
            block.style.opacity = "1";
          });
        });
      } else {
        allTopics.forEach((topic) => {
          topic.style.opacity = "1";
        });
        allTranscriptBlocks.forEach((block) => {
          block.style.opacity = "1";
        });
      }
    },

    rounder(number) {
      return number.toFixed(2);
    },
    handleCommentClick(commentId) {
      document.querySelector('[x-ref="comment_tab"]').click();

      setTimeout(() => {
        const commentElement = document.getElementById(`comment-${commentId}`);
        if (commentElement) {
          commentElement.scrollIntoView({ behavior: "smooth", block: "start" });
          commentElement.classList.add("highlight");

          setTimeout(() => {
            commentElement.classList.remove("highlight");
          }, 1000);
        }
      }, 300);
    },
  };
};
