import { createSnippet } from "./create-snippet";

export const callVideoPlayer = () => {
  return {
    isPlaying: false,
    isVisibleForward: false,
    isVisibleBackward: false,
    currentTime: 0,
    duration: 0,
    currentTimeTxt: "00:00",
    durationTxt: "00:00",
    video: null,
    playbackSpeed: 1,
    isVideoLoading: false,
    isSoundOn: true,
    videoLoadingAttempts: 0,
    areCaptionsOn: false,
    isCaptionsLabelEnabled: false,
    isVideoReady: true,
    snippet: createSnippet(),
    from: undefined,
    to: undefined,

    init(duration) {
      this.video = document.getElementById("call-video");
      if (this.video) {
        let track = this.video.textTracks && this.video.textTracks[0];
        track.mode = "hidden";
        this.snippet.init();
        if (duration !== undefined && duration !== null) {
          this.duration = duration;
          this.durationTxt = this.formatTime(this.duration);
        }
      }
      this.areCaptionsOn = false;
    },

    initializeSpeedChooser() {
      const playbackSpeedChooser = document.getElementById(
        "playback-speed-chooser",
      );

      if (playbackSpeedChooser) {
        let availableSpeeds = [0.5, 1, 1.25, 1.5, 1.75, 2, 2.5];

        for (let i = 0; i < availableSpeeds.length; i++) {
          let speed = availableSpeeds[i];

          let button = document.createElement("button");
          button.textContent = `${speed}x`;
          button.setAttribute(
            "x-on:click",
            `playbackSpeed = ${speed}; video.playbackRate = playbackSpeed; playbackSpeedChooserOpen = false`,
          );
          button.setAttribute(
            "x-bind:class",
            `{'bg-neutral-100 text-gray-900': playbackSpeed === ${speed}}`,
          );

          button.classList.add(
            "group",
            "relative",
            "flex",
            "w-full",
            "cursor-default",
            "select-none",
            "items-center",
            "justify-between",
            "rounded",
            "px-2",
            "py-1.5",
            "outline-none",
            "hover:bg-neutral-100",
            "hover:text-gray-900",
            "data-[disabled]:pointer-events-none",
            "data-[disabled]:opacity-50",
            "cursor-pointer",
          );

          playbackSpeedChooser.appendChild(button);
        }
      }
    },

    updateCurrentTime() {
      if (this.video) {
        if (this.to !== undefined && this.from !== undefined) {
          this.currentTime = this.video.currentTime - this.from;
        } else {
          this.currentTime = this.video.currentTime;
        }
        if (
          this.video.currentTime < this.from ||
          this.video.currentTime > this.to
        ) {
          this.video.currentTime = this.from;
          this.currentTime = this.video.currentTime - this.from;
        }

        this.currentTimeTxt = this.formatTime(this.currentTime);
        window.dispatchEvent(
          new CustomEvent("transcriptTimeUpdateHighlight", {
            detail: { startTime: this.currentTime },
          }),
        );
      }
    },

    setLimit(from, to) {
      this.from = from;
      this.to = to;
    },

    updateDuration() {
      if (this.video) {
        if (this.from !== undefined && this.to !== undefined) {
          this.duration = this.to - this.from;
        } else {
          this.duration = this.video.duration;
        }

        this.durationTxt = this.formatTime(this.duration);
      }
    },

    async play() {
      if (this.video && this.video.paused) {
        await this.video.play();
        this.isPlaying = !this.video.paused;
      }
    },

    async pause() {
      if (this.video && !this.video.paused) {
        await this.video.pause();
        this.isPlaying = !this.video.paused;
      }
    },

    async playPause() {
      if (this.video) {
        if (document.fullscreenElement) {
          this.isPlaying = this.video.paused;
          return;
        }

        if (this.video.paused) {
          return await this.play();
        }

        return await this.pause();
      }
    },

    backward() {
      if (this.video) {
        if (this.video.currentTime - 10 > 0) {
          this.video.currentTime -= 10;
        } else {
          this.video.currentTime = 0;
        }

        this.isVisibleBackward = true;
        const backwardContainer = document.getElementById("backward-container");
        backwardContainer.style.transition = "opacity 0s";
        setTimeout(() => {
          backwardContainer.style.transition = "opacity 1s ease";
          this.isVisibleBackward = false;
        }, 100);
      }
    },

    forward() {
      if (this.video) {
        if (this.video.currentTime + 10 < this.video.duration) {
          this.video.currentTime += 10;
        } else {
          this.video.currentTime = this.video.duration;
        }

        const forwardContainer = document.getElementById("forward-container");
        forwardContainer.style.transition = "opacity 0s";
        this.isVisibleForward = true;
        setTimeout(() => {
          forwardContainer.style.transition = "opacity 1s ease";
          this.isVisibleForward = false;
        }, 100);
      }
    },

    changePlaybackSpeed() {
      if (this.video) {
        const supportedPlaybackSpeedsMap = new Map([
          [0.5, 1],
          [1, 1.25],
          [1.25, 1.5],
          [1.5, 1.75],
          [1.75, 2],
          [2, 2.5],
          [2.5, 0.5],
        ]);

        if (supportedPlaybackSpeedsMap.has(this.playbackSpeed)) {
          this.playbackSpeed = supportedPlaybackSpeedsMap.get(
            this.playbackSpeed,
          );
        } else {
          this.playbackSpeed =
            this.playbackSpeed > 2.5 ? 0.5 : this.playbackSpeed + 0.25;
        }

        this.video.playbackRate = this.playbackSpeed;
      }
    },

    toggleFullScreen() {
      if (!document.fullscreenElement) {
        this.video.requestFullscreen().catch((err) => {
          alert(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
          );
        });
      } else {
        document.exitFullscreen();
      }
    },

    soundClicked() {
      if (this.video) {
        if (this.isSoundOn) {
          this.video.volume = 0;
          this.isSoundOn = false;
        } else {
          this.video.volume = 1;
          this.isSoundOn = true;
        }
      }
    },

    updateVolume() {
      if (this.video) {
        const isMuted = this.video.muted;
        const volumeLevel = this.video.volume;
        this.isSoundOn = volumeLevel > 0 && !isMuted;
      }
    },

    updatePlaybackSpeed() {
      if (this.video) {
        this.playbackSpeed = this.video.playbackRate;
      }
    },

    setCurrentTime(event, element) {
      if (this.video) {
        const rect = element.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const percentage = (x / rect.width) * 100;

        const newTime = (percentage / 100) * this.duration;
        if (this.from !== undefined && this.to !== undefined) {
          this.video.currentTime = this.from + newTime;
        } else {
          this.video.currentTime = newTime;
        }
      }
    },

    setTranscriptTime(time) {
      if (this.video) {
        this.video.currentTime = time;
      }
    },

    setVideoPlayerPoolingUrl(id) {
      const videoPlayerWrapper = document.getElementById(
        "video-player-wrapper",
      );
      if (videoPlayerWrapper) {
        this.videoLoadingAttempts++;
        videoPlayerWrapper.setAttribute(
          "hx-get",
          `/molecule/calls/${id}/video_player?attempt_num=${this.videoLoadingAttempts}`,
        );
      }
    },

    getVideoPosterUrl(video_poster) {
      return this.isVideoLoading ? "" : video_poster;
    },

    checkTrackMode() {
      if (this.video) {
        let track = this.video.textTracks && this.video.textTracks[0];
        if (track) {
          this.areCaptionsOn = track.mode === "showing";
        } else {
          this.areCaptionsOn = false;
        }
      }
    },

    captionsClicked() {
      if (this.video) {
        let track = this.video.textTracks && this.video.textTracks[0];

        if (track) {
          this.areCaptionsOn = !this.areCaptionsOn;

          if (this.areCaptionsOn) {
            track.mode = "showing";
            this.isCaptionsLabelEnabled = true;
            setTimeout(() => {
              this.isCaptionsLabelEnabled = false;
            }, 3000);
          } else {
            track.mode = "hidden";
          }
        }
      }
    },

    getSubtitleBlob(subtitle) {
      return URL.createObjectURL(new Blob([subtitle], { type: "text/vtt" }));
    },

    grayscaleTopicsByName(name) {
      const allTopics = document.querySelectorAll(`.common-topic-name`);
      const allTranscriptBlocks = document.querySelectorAll(
        `.common-transcript-block`,
      );
      const lowOpacity = "0.1";
      if (name) {
        const topics = document.querySelectorAll(`.topic-name-${name}`);
        allTopics.forEach((topic) => {
          topic.style.opacity = lowOpacity;
        });
        allTranscriptBlocks.forEach((block) => {
          block.style.opacity = lowOpacity;
        });
        topics.forEach((topic) => {
          topic.style.opacity = "1";
          const topicName = topic.getAttribute("name");
          const allTranscriptBlocksByName = document.querySelectorAll(
            `[data-participant-name="${topicName}"]`,
          );
          allTranscriptBlocksByName.forEach((block) => {
            block.style.opacity = "1";
          });
        });
      } else {
        allTopics.forEach((topic) => {
          topic.style.opacity = "1";
        });
        allTranscriptBlocks.forEach((block) => {
          block.style.opacity = "1";
        });
      }
    },

    rounder(number) {
      return number.toFixed(2);
    },

    formatTime(time) {
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = Math.floor(time % 60);

      if (hours > 0) {
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      }
    },
  };
};
