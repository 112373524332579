export function sharedVideoPlayer() {
  return {
    isPlaying: false,
    isVisibleForward: false,
    isVisibleBackward: false,
    currentTime: 0,
    duration: 0,
    currentTimeTxt: "00:00",
    durationTxt: "00:00",
    _video: null,
    playbackSpeed: 1,
    isVideoLoading: false,
    isSoundOn: true,
    areCaptionsOn: false,
    transition: "opacity 1s ease",
    videoLoadingAttempts: 0,
    from: undefined,
    to: undefined,
    updateCurrentTime() {
      this._video = document.getElementById("call-video");
      if (!this._video) {
        return;
      }
      if (this.to !== undefined && this.from !== undefined) {
        this.currentTime = this._video.currentTime - this.from;
      } else {
        this.currentTime = this._video.currentTime;
      }
      if (
        this._video.currentTime < this.from ||
        this._video.currentTime > this.to
      ) {
        this._video.currentTime = this.from;
        this.currentTime = this._video.currentTime - this.from;
      }
    },
    rounder(number) {
      return number.toFixed(2);
    },
    init() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      this._isVideoLoading = false;
      this._currentTime = 0;
    },
    setLimit(from, to) {
      this.from = from;
      this.to = to;
    },
    async play() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video && this._video.paused) {
        await this._video.play();
        this.isPlaying = !this._video.paused;
      }
    },
    async pause() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video && !this._video.paused) {
        await this._video.pause();
        this.isPlaying = !this._video.paused;
      }
    },
    async playPause() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        if (document.fullscreenElement) {
          this.isPlaying = this._video.paused;
          return;
        }

        if (this._video && this._video.paused) {
          await this._video.play();
          this.isPlaying = !this._video.paused;
          return;
        }

        if (this._video && !this._video.paused) {
          await this._video.pause();
          this.isPlaying = !this._video.paused;
          return;
        }
      }
    },
    backward() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        if (this._video.currentTime - 10 > 0) {
          this._video.currentTime -= 10;
        } else {
          this._video.currentTime = 0;
        }

        this.transition = "opacity 0s";
        this.isVisibleBackward = true;
        setTimeout(() => {
          this.transition = "opacity 1s ease";
          this.isVisibleBackward = false;
        }, 1);
      }
    },
    forward() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        if (this._video.currentTime + 10 < this._video.duration) {
          this._video.currentTime += 10;
        } else {
          this._video.currentTime = this._video.duration;
        }
        this.transition = "opacity 0s";
        this.isVisibleForward = true;
        setTimeout(() => {
          this.transition = "opacity 1s ease";
          this.isVisibleForward = false;
        }, 1);
      }
    },
    updateDuration(from, to) {
      this.from = from;
      this.to = to;
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        if (this.from !== undefined && this.to !== undefined) {
          this.duration = this.to - this.from;
        } else {
          this.duration = this._video.duration;
        }
        this.durationTxt = this.formatTime(this.duration);
      }
    },
    toggleFullScreen() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (!document.fullscreenElement) {
        this._video.requestFullscreen().catch((err) => {
          alert(
            `Error attempting to enable full-screen mode: ${err.message} (${err.name})`,
          );
        });
      } else {
        document.exitFullscreen();
      }
    },

    soundClicked() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        if (this.isSoundOn) {
          this._video.volume = 0;
          this.isSoundOn = false;
        } else {
          this._video.volume = 1;
          this.isSoundOn = true;
        }
      }
    },

    updateVolume() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        const isMuted = this._video.muted;
        const volumeLevel = this._video.volume;
        this.isSoundOn = volumeLevel > 0 && !isMuted;
      }
    },

    updatePlaybackSpeed() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        this.playbackSpeed = this._video.playbackRate;
      }
    },

    setCurrentTime(event, element) {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        const rect = element.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const percentage = (x / rect.width) * 100;
        if (this.from !== undefined && this.to !== undefined) {
          const newTime =
            (this.from / this._video.duration + percentage / 100) *
            this.duration;
          this._video.currentTime = newTime;
        } else {
          const newTime = (percentage / 100) * this.duration;
          this._video.currentTime = newTime;
        }
      }
    },
    formatTime(time) {
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = Math.floor(time % 60);

      if (hours > 0) {
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      } else {
        return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      }
    },
    changePlaybackSpeed() {
      if (!this._video) {
        this._video = document.getElementById("call-video");
      }
      if (this._video) {
        const supportedPlaybackSpeedsMap = new Map([
          [0.5, 1],
          [1, 1.25],
          [1.25, 1.5],
          [1.5, 1.75],
          [1.75, 2],
          [2, 2.5],
          [2.5, 0.5],
        ]);

        if (supportedPlaybackSpeedsMap.has(this.playbackSpeed)) {
          this.playbackSpeed = supportedPlaybackSpeedsMap.get(
            this.playbackSpeed,
          );
        } else {
          this.playbackSpeed =
            this.playbackSpeed > 2.5 ? 0.5 : this.playbackSpeed + 0.25;
        }

        this._video.playbackRate = this.playbackSpeed;
      }
    },
    grayscaleTopicsByName(name) {
      const allTopics = document.querySelectorAll(`.common-topic-name`);
      const allTranscriptBlocks = document.querySelectorAll(
        `.common-transcript-block`,
      );
      const lowOpacity = "0.1";
      if (name) {
        const topics = document.querySelectorAll(`.topic-name-${name}`);
        allTopics.forEach((topic) => {
          topic.style.opacity = lowOpacity;
        });
        allTranscriptBlocks.forEach((block) => {
          block.style.opacity = lowOpacity;
        });
        topics.forEach((topic) => {
          topic.style.opacity = "1";
          const topicName = topic.getAttribute("name");
          const allTranscriptBlocksByName = document.querySelectorAll(
            `[data-participant-name="${topicName}"]`,
          );
          allTranscriptBlocksByName.forEach((block) => {
            block.style.opacity = "1";
          });
        });
      } else {
        allTopics.forEach((topic) => {
          topic.style.opacity = "1";
        });
        allTranscriptBlocks.forEach((block) => {
          block.style.opacity = "1";
        });
      }
    },
  };
}
