export const integrations = (tabSelected) => {
  return {
    connectingIntegration: false,
    tabSelected: tabSelected,
    redirectUrl: "",

    init() {
      this.tabRepositionMarker(this.$refs[this.tabSelected]);
    },

    initializeTabMarker() {
      this.tabRepositionMarker(this.$refs[this.tabSelected]);
    },

    tabRepositionMarker(tabButton) {
      this.$refs.tabMarker.style.width = tabButton.offsetWidth + "px";
      this.$refs.tabMarker.style.height = tabButton.offsetHeight + "px";
      this.$refs.tabMarker.style.left = tabButton.offsetLeft + "px";
      this.updateRedirectUrl(tabButton);
    },

    tabContentActive(tabEl) {
      return this.tabSelected == tabEl.id;
    },

    updateRedirectUrl(selectedTabEelement) {
      this.redirectUrl = selectedTabEelement.getAttribute("hx-get");
    },
  };
};
