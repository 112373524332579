export const multiSelect = () => {
  return {
    selectOpen: false,
    selectedItems: [],
    selectableItems: [],
    selectableItemActive: null,
    selectId: null,
    selectKeydownValue: "",
    selectKeydownTimeout: 1000,
    selectKeydownClearTimeout: null,
    selectDropdownPosition: "bottom",

    init(selected_choices, choices, id) {
      if (selected_choices) {
        this.selectedItems = selected_choices;
      }
      if (choices) {
        this.selectableItems = choices;
      }
      this.selectId = id;
      this.$watch("selectOpen", () => {
        setTimeout(() => {
          this.selectScrollToActiveItem();
        }, 10);
        this.selectPositionUpdate();
        window.addEventListener("resize", (event) => {
          this.selectPositionUpdate();
        });
      });
      for (const item of this.selectedItems) {
        const optionEl = this.$refs["option-" + item.value];
        optionEl.selected = true;
        const changeEvent = new CustomEvent("change", {
          bubbles: true,
          cancelable: true,
        });
        this.$refs["multi-select"].dispatchEvent(changeEvent);
      }
    },

    isSelected(item) {
      // We should look by item id
      return this.selectedItems.find(
        (selectedItem) => selectedItem.value === item.value,
      );
    },

    toggleSelect(item) {
      // update the select element options, that is used for the form
      const optionEl = this.$refs["option-" + item.value];
      optionEl.selected = !optionEl.selected;
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter(
          (selected) => selected.value !== item.value,
        );
      } else {
        this.selectedItems = [...this.selectedItems, item];
      }
      const changeEvent = new CustomEvent("change", {
        bubbles: true,
        cancelable: true,
      });
      this.$refs["multi-select"].dispatchEvent(changeEvent);
    },

    resetSelect() {
      this.selectedItems = [];
      this.selectableItems.forEach((item) => {
        const optionEl = this.$refs["option-" + item.value];
        optionEl.selected = false;
      });
      const changeEvent = new CustomEvent("change", {
        bubbles: true,
        cancelable: true,
      });
      this.$refs["multi-select"].dispatchEvent(changeEvent);
    },

    selectableItemIsActive(item) {
      return (
        this.selectableItemActive &&
        this.selectableItemActive.value == item.value
      );
    },

    selectableItemActiveNext() {
      let index = this.selectableItems.indexOf(this.selectableItemActive);
      if (index < this.selectableItems.length - 1) {
        this.selectableItemActive = this.selectableItems[index + 1];
        this.selectScrollToActiveItem();
      }
    },

    selectableItemActivePrevious() {
      let index = this.selectableItems.indexOf(this.selectableItemActive);
      if (index > 0) {
        this.selectableItemActive = this.selectableItems[index - 1];
        this.selectScrollToActiveItem();
      }
    },

    selectScrollToActiveItem() {
      if (this.selectableItemActive) {
        this.activeElement = document.getElementById(
          this.selectableItemActive.value + "-" + this.selectId,
        );
        this.newScrollPos =
          this.activeElement.offsetTop +
          this.activeElement.offsetHeight -
          this.$refs.selectableItemsList.offsetHeight;
        if (this.newScrollPos > 0) {
          this.$refs.selectableItemsList.scrollTop = this.newScrollPos;
        } else {
          this.$refs.selectableItemsList.scrollTop = 0;
        }
      }
    },

    selectKeydown(event) {
      if (event.keyCode >= 65 && event.keyCode <= 90) {
        this.selectKeydownValue += event.key;
        this.selectedItemBestMatch = this.selectItemsFindBestMatch();
        if (this.selectedItemBestMatch) {
          if (this.selectOpen) {
            this.selectableItemActive = selectedItemBestMatch;
            this.selectScrollToActiveItem();
          } else {
            this.selectedItem = this.selectableItemActive =
              this.selectedItemBestMatch;
          }
        }

        if (this.selectKeydownValue != "") {
          clearTimeout(this.selectKeydownClearTimeout);
          this.selectKeydownClearTimeout = setTimeout(() => {
            this.selectKeydownValue = "";
          }, this.selectKeydownTimeout);
        }
      }
    },

    selectItemsFindBestMatch() {
      this.typedValue = this.selectKeydownValue.toLowerCase();
      var bestMatch = null;
      var bestMatchIndex = -1;
      for (var i = 0; i < this.selectableItems.length; i++) {
        var title = this.selectableItems[i].title.toLowerCase();
        var index = title.indexOf(this.typedValue);
        if (
          index > -1 &&
          (bestMatchIndex == -1 || index < bestMatchIndex) &&
          !this.selectableItems[i].disabled
        ) {
          bestMatch = this.selectableItems[i];
          bestMatchIndex = index;
        }
      }
      return bestMatch;
    },

    selectPositionUpdate() {
      this.selectDropdownBottomPos =
        this.$refs.selectButton.getBoundingClientRect().top +
        this.$refs.selectButton.offsetHeight +
        parseInt(
          window.getComputedStyle(this.$refs.selectableItemsList).maxHeight,
        );
      if (window.innerHeight < this.selectDropdownBottomPos) {
        this.selectDropdownPosition = "top";
      } else {
        this.selectDropdownPosition = "bottom";
      }
    },
  };
};
