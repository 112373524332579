export const myProfile = () => {
  return {
    connectingIntegration: false,
    isWaitingForResponse: false,
    tabSelected: "information",
    redirectUrl: "",

    init() {
      this.tabRepositionMarker(this.$refs.tabButtons.firstElementChild);
    },

    tabButtonClicked(tabId) {
      this.tabSelected = tabId;
      this.tabRepositionMarker(this.$refs[tabId]);
    },

    tabRepositionMarker(tabButton) {
      this.$refs.tabMarker.style.width = tabButton.offsetWidth + "px";
      this.$refs.tabMarker.style.height = tabButton.offsetHeight + "px";
      this.$refs.tabMarker.style.left = tabButton.offsetLeft + "px";
      this.updateRedirectUrl(tabButton);
    },

    tabContentActive(tabEl) {
      return this.tabSelected == tabEl.id;
    },

    updateRedirectUrl(selectedTabEelement) {
      this.redirectUrl = selectedTabEelement.getAttribute("hx-get");
    },
  };
};
