export const dateFormatter = (dateInIsoString) => {
  const date = new Date(dateInIsoString);
  if (isNaN(date)) {
    return { date: "Invalid Date" };
  }
  const fomattedDate = Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
    // second: "2-digit",
  }).format(date);
  return { date: fomattedDate };
};

export const dateFormatterDDMMYYYY = (dateInIsoString) => {
  const date = new Date(dateInIsoString);
  if (isNaN(date)) {
    return { date: "Invalid Date" };
  }
  const formattedDate = Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
  return { date: formattedDate };
};

export function timeAgo(isoString) {
  const date = new Date(isoString);
  const now = new Date();
  const secondsAgo = Math.floor((now - date) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const interval in intervals) {
    const intervalSeconds = intervals[interval];
    if (secondsAgo >= intervalSeconds) {
      const count = Math.floor(secondsAgo / intervalSeconds);
      return { date: `${count} ${interval}${count !== 1 ? "s" : ""} ago` };
    }
  }

  return { date: "just now" };
}
