export function createSnippet() {
  return {
    parentDiv: undefined,
    childDiv: undefined,
    isCreateSnippetOpened: false,
    left: "35%",
    width: "15%",
    selected: "none",
    selectedFromPercentage: 0,
    selectedToPercentage: 0,
    isSelectSnippetNameOpened: false,
    isSnippetCreated: false,
    isCopyPressed: false,
    transformToSecondsFromDuration(percentage, duration) {
      return (percentage / 100) * duration;
    },
    init() {
      this.parentDiv = document.getElementById("create-snippet-parent-div");
      this.childDiv = document.getElementById("create-snippet-child-div");
    },
    leftDown() {
      this.selected = "left";
    },
    rightDown() {
      this.selected = "right";
    },
    clear() {
      this.selected = "none";
    },
    mouseDrag(event) {
      if (this.selected === "left" && this.parentDiv && this.childDiv) {
        const parentRect = this.parentDiv.getBoundingClientRect();
        const childRect = this.childDiv.getBoundingClientRect();

        const leftRelativePosition = event.clientX - parentRect.left;
        const leftPercentagePosition =
          (leftRelativePosition / parentRect.width) * 100;
        const rightPerentagePosition =
          ((parentRect.right - childRect.right) / parentRect.width) * 100;

        this.width =
          100 - leftPercentagePosition - rightPerentagePosition + "%";
        this.left = leftPercentagePosition + "%";
      } else if (this.selected === "right" && this.parentDiv && this.childDiv) {
        const parentRect = this.parentDiv.getBoundingClientRect();
        const childRect = this.childDiv.getBoundingClientRect();

        const leftRelativePosition = childRect.left - parentRect.left;
        const leftPercentagePosition =
          (leftRelativePosition / parentRect.width) * 100;
        const rightPerentagePosition =
          ((parentRect.right - event.clientX) / parentRect.width) * 100;

        this.width =
          100 - leftPercentagePosition - rightPerentagePosition + "%";
      }
    },
    createClick() {
      this.selectedFromPercentage = +this.left.replace("%", "");
      this.selectedToPercentage =
        this.selectedFromPercentage + +this.width.replace("%", "");
      this.isSelectSnippetNameOpened = true;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.isCopyPressed = true;
      let that = this;
      setTimeout(function () {
        that.isCopyPressed = false;
      }, 3000);
    },
  };
}
