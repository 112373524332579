import * as echarts from "echarts";
const fullConfig = require("../tailwind.config.js");

export const displayChart = (data) => {
  return {
    lineChart: null,
    barChart: null,
    lineChartInstance: null,
    barChartInstance: null,
    lineTotalChartOptions: null,
    barTotalChartOptions: null,
    lineAverageChartOptions: null,
    barAverageChartOptions: null,
    totalCalls: 0,
    totalAverageDuration: 0,
    totalAverageTeamDuration: 0,
    totalAverageTeamCalls: 0,

    init() {
      this.calculateTotals();
      this.cacheElements();
      this.initChartsOptions();
    },

    cacheElements() {
      this.lineChart = document.getElementById("line-chart");
      this.barChart = document.getElementById("bar-chart");

      if (this.lineChart) {
        this.lineChartInstance = echarts.init(this.lineChart);
      }
      if (this.barChart) {
        this.barChartInstance = echarts.init(this.barChart);
      }
      window.addEventListener("resize", () => {
        if (this.lineChart) {
          this.lineChartInstance.resize();
        }
        if (this.barChart) {
          this.barChartInstance.resize();
        }
      });
    },
    initChartsOptions() {
      this.lineTotalChartOptions = this.lineChartOptions(
        data.by_period.map((d) => d.period),
        data.by_period.map((d) => d.count),
        "Number of calls",
        "Number of calls",
        data.by_period.map((d) => d.count),
      );
      this.lineAverageChartOptions = this.lineChartOptions(
        data.by_period.map((d) => d.period),
        data.by_period.map((d) => d.average_duration / 60),
        "Time(minutes)",
        "Time(minutes)",
        data.by_period.map((d) => this.convertFromSeconds(d.average_duration)),
      );
      this.barTotalChartOptions = this.barChartOptions(
        data.by_user.map((d) => d.count),
        data.by_user.map((d) => d.user_name),
        data.by_user.map((d) => d.count),
        this.getColors("total"),
        this.totalAverageTeamCalls,
      );
      this.barAverageChartOptions = this.barChartOptions(
        data.by_user.map((d) => d.average_duration),
        data.by_user.map((d) => d.user_name),
        data.by_user.map((d) => this.convertFromSeconds(d.average_duration)),
        this.getColors("average"),
        this.convertFromSeconds(this.totalAverageTeamDuration),
      );
    },

    calculateTotals() {
      this.totalCalls = data.by_period.length
        ? data.by_period.reduce((acc, p) => acc + p.count, 0)
        : 0;
      this.totalAverageTeamCalls = parseFloat(
        (this.totalCalls / data.by_user.length).toFixed(1),
      );
      this.totalAverageDuration = data.by_user.length
        ? data.by_user.reduce((acc, p) => acc + p.average_duration, 0)
        : 0;
      this.totalAverageTeamDuration =
        this.totalAverageDuration / data.by_user.length;
    },

    getColors(type) {
      const averageTotal =
        type === "total"
          ? this.totalCalls / data.by_user.length
          : this.totalAverageDuration / data.by_user.length;

      const percentageDifferences = this.calculatePercentageDifferenceArray(
        data.by_user.map((c) =>
          type === "total" ? c.count : c.average_duration,
        ),
        averageTotal,
      );

      return percentageDifferences.map(
        (difference) =>
          fullConfig.theme.extend.colors["primary-blue"][difference],
      );
    },

    convertFromSeconds(seconds) {
      const referenceDate = new Date(Date.UTC(1970, 0, 1));
      const endDate = new Date(referenceDate.getTime() + seconds * 1000);

      const years = endDate.getUTCFullYear() - referenceDate.getUTCFullYear();
      const months = endDate.getUTCMonth() - referenceDate.getUTCMonth();
      const days = endDate.getUTCDate() - referenceDate.getUTCDate();
      const hours = Math.floor((seconds / 3600) % 24);
      const minutes = Math.floor((seconds / 60) % 60);
      const remainingSeconds = Math.floor(seconds % 60);

      const components = [
        { unit: "y", value: years },
        { unit: "m", value: months },
        { unit: "d", value: days },
        { unit: "h", value: hours },
        { unit: "m", value: minutes },
        { unit: "s", value: remainingSeconds },
      ];

      return components
        .filter((component) => component.value > 0)
        .map((component) => `${component.value}${component.unit}`)
        .join(" ");
    },

    calculatePercentageDifferenceArray(values, average) {
      return values.map((value) => {
        const percentageDifference = ((value - average) / average) * 100;
        if (percentageDifference > 50) return "500";
        if (percentageDifference > 20) return "400";
        if (percentageDifference > -20) return "300";
        if (percentageDifference > -50) return "200";
        return "100";
      });
    },

    getWeekStartDate(week, year) {
      const startDate = new Date(year, 0, 1);
      const firstMonday = new Date(startDate);
      while (firstMonday.getDay() !== 1)
        firstMonday.setDate(firstMonday.getDate() + 1);

      const weekStartDate = new Date(firstMonday);
      weekStartDate.setDate(firstMonday.getDate() + (week - 1) * 7);

      const day = String(weekStartDate.getDate()).padStart(2, "0");
      const month = String(weekStartDate.getMonth() + 1).padStart(2, "0");

      return `Week of ${day}/${month}`;
    },

    formatWeek(inputDate) {
      const [week, year] = inputDate.split("/").map((Number, Number));
      return this.getWeekStartDate(week, year);
    },

    lineChartOptions(xData, yData, yLabel, tooltipTitle, valueLabels) {
      return {
        grid: { top: 10, right: 70, left: 70 },
        xAxis: {
          type: "category",
          data: xData,
          name: "Period",
          nameTextStyle: {
            fontFamily: "Plus Jakarta Sans",
            color: "#667085",
            margin: 15,
          },
          nameLocation: "center",
          nameGap: 50,
          boundaryGap: false,
          axisLabel: {
            show: true,
            fontFamily: "Plus Jakarta Sans",
            color: "#667085",
            formatter: (param) => this.formatWeek(param),
          },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          minorSplitLine: { show: false },
        },
        yAxis: {
          axisTick: { interval: 1 },
          type: "value",
          name: yLabel,
          nameTextStyle: {
            fontFamily: "Plus Jakarta Sans",
            color: "#667085",
            margin: 15,
          },
          nameLocation: "center",
          nameGap: 50,
          axisLabel: {
            show: true,
            fontFamily: "Plus Jakarta Sans",
            color: "#667085",
          },
        },
        legend: {},
        tooltip: {
          trigger: "axis",
          position: (point) => [point[0] + 10, point[1] - 50],
          padding: [0, 0],
          backgroundColor: "#ffffff",
          borderColor: "#ffffff",
          formatter: (params) => `
            <div class="inline-flex flex-col items-start shadow-md">
              <div
                class="flex items-center gap-2.5 self-stretch p-2 px-2.5 pt-1"
                style="border-radius: 0.25rem 0.25rem 0rem 0rem;
                   border-top: 1px solid var(--Blue-gray-100, #EAECF5);
                   border-right: 1px solid var(--Blue-gray-100, #EAECF5);
                   border-left: 1px solid var(--Blue-gray-100, #EAECF5);
                   background: var(--Blue-gray-50, #F8F9FC);"
              >
                <span
                  class="text-gray-500"
                  style="font-family: 'Plus Jakarta Sans'; font-size: 0.625rem; font-style: normal; font-weight: 600; line-height: 1rem;"
                >
                  ${this.formatWeek(params[0].name)}
                </span>
              </div>
              <div
                class="flex items-center gap-2.5 p-2 px-2.5 pt-2.5"
                style="border-radius: 0rem 0rem 0.25rem 0.25rem;
                    border-right: 1px solid var(--Blue-gray-100, #EAECF5);
                    border-bottom: 1px solid var(--Blue-gray-100, #EAECF5);
                    border-left: 1px solid var(--Blue-gray-100, #EAECF5);
                    background: var(--White, #FFF);"
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5" cy="5" r="5" fill="#2E90FA" />
                </svg>
                <span
                  class="text-gray-500"
                  style="align-self: stretch; font-family: 'Plus Jakarta Sans'; font-size: 0.625rem; font-style: normal; font-weight: 400; line-height: 1.0625rem;"
                >
                  ${tooltipTitle}: 
                  <span
                    class="text-primary-500"
                    style="font-family: 'Plus Jakarta Sans'; font-size: 0.625rem; font-style: normal; font-weight: 700; line-height: 1.25rem;"
                  >
                    ${valueLabels[params[0].dataIndex]}
                  </span>
                </span>
              </div>
            </div>`,
        },
        toolbox: { show: false },
        series: [
          {
            data: yData,
            symbol: "circle",
            showSymbol: false,
            type: "line",
            smooth: true,
            lineStyle: { color: "#1570EF", width: 5 },
            itemStyle: {
              color: "#B2DDFF",
              borderColor: "#53B1FD",
              borderRadius: 1,
              borderWidth: 10,
              symbolSize: 10,
            },
            emphasis: {
              focus: "series",
              itemStyle: {
                color: "#B2DDFF",
                borderColor: "#53B1FD",
                borderWidth: 15,
                symbolSize: 10,
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0, color: "rgba(0, 123, 255, 0.6)" },
                  { offset: 1, color: "rgba(0, 123, 255, 0.0)" },
                ],
                global: false,
              },
            },
          },
        ],
      };
    },

    barChartOptions(xData, yData, valueLabels, colors, averageValue) {
      return {
        grid: { top: 30, bottom: 10, left: 10, right: 100, containLabel: true },
        xAxis: {
          type: "value",
          max: "dataMax",
          show: false,
          axisLine: { show: false },
          splitLine: { show: false },
          minorSplitLine: { show: false },
        },
        yAxis: {
          type: "category",
          data: yData,
          inverse: true,
          labelLine: { show: false },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            fontFamily: "Plus Jakarta Sans",
            color: "#667085",
          },
          axisLine: { show: false },
        },
        series: [
          {
            markLine: {
              symbol: ["none", "none"],
              label: {
                show: true,
                position: "start",
                formatter: `Team average: ${averageValue}`,
                borderType: "solid",
                backgroundColor: "#344054",
                borderColor: "#344054",
                borderWidth: 5,
                padding: [2, 10],
                borderRadius: 10,
                color: "#FFFFFF",
                fontFamily: "Plus Jakarta Sans",
              },
              lineStyle: {
                color: "#344054",
                width: 2,
              },

              data: [
                {
                  type: "average",
                },
              ],
              silent: true,
            },
            emphasis: { disabled: true },
            silent: true,
            itemStyle: { borderRadius: [5, 5, 5, 5] },
            realtimeSort: true,
            name: "X",
            type: "bar",
            labelLine: { show: false },
            data: xData.map((value, index) => {
              return {
                value: value,
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      { offset: 0, color: "#def2ff" },
                      {
                        offset: 1,
                        color: colors[index % colors.length],
                      },
                    ],
                  },
                },
              };
            }),
            label: {
              show: true,
              position: "right",
              valueAnimation: true,
              formatter: (params) => `${valueLabels[params.dataIndex]}`,
              fontFamily: "Plus Jakarta Sans",
              color: "#667085",
            },
          },
        ],
      };
    },

    setDisplayChart(type) {
      if (type === "meetings_volume") {
        if (this.lineChart) {
          echarts
            .getInstanceByDom(this.lineChart)
            .setOption(this.lineTotalChartOptions);
        }
        if (this.barChart) {
          echarts
            .getInstanceByDom(this.barChart)
            .setOption(this.barTotalChartOptions);
        }
      } else {
        if (this.lineChart) {
          echarts
            .getInstanceByDom(this.lineChart)
            .setOption(this.lineAverageChartOptions);
        }

        if (this.barChart) {
          echarts
            .getInstanceByDom(this.barChart)
            .setOption(this.barAverageChartOptions);
        }
      }
    },
  };
};
