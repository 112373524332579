export const integrationForm = (integration_type, submit_url) => {
  return {
    integrationType: integration_type,
    submitUrl: submit_url,
    tabSelected: "",

    init() {
      document.body.addEventListener("htmx:beforeSwap", function (evt) {
        if (evt.detail.xhr.status === 400) {
          evt.detail.shouldSwap = true;
          evt.detail.target = htmx.find("#form-error");
          htmx.find("#form-error").classList.remove("hidden");
        }
      });
    },

    initializeTabMarker() {
      const previousTab = this.getPreviousPage().split("/").pop();

      this.tabSelected = previousTab + "_integrations";
      this.tabRepositionMarker(this.$refs[this.tabSelected]);
    },

    tabRepositionMarker(tabButton) {
      this.$refs.tabMarker.style.width = tabButton.offsetWidth + "px";
      this.$refs.tabMarker.style.height = tabButton.offsetHeight + "px";
      this.$refs.tabMarker.style.left = tabButton.offsetLeft + "px";
    },

    getPreviousPage() {
      const referrer = document.referrer;
      return referrer && referrer.length > 0 ? referrer : "/all";
    },
  };
};
